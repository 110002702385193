<template>
  <b-row class="page-vault">
    <b-col>
      <PageHeader :title="$t('general.vault.title')">
        <button
          type="button"
          @click="newMedia"
          class="base-button post-create__button post-create__button--header"
        >
          <span class="base-button__font">
            {{ $t("general.vault.addNew") }}
          </span>
        </button>
      </PageHeader>

      <div class="page-vault__list">
        <InfinityScroll
          v-if="vaultList && vaultList.length"
          :items="vaultList"
          :has-more="Boolean(vault.next_page_url)"
          @rich-end="loadMore"
          is-grid
        >
          <template #default="{ item: card }">
            <PostTile
              :post="card"
              inactive
              is-vault
              selectable
              tile-type="select"
              @chose="toggleModal"
              @unselect="onUnselect"
            />
          </template>
        </InfinityScroll>
      </div>

      <transition name="fade">
        <div v-if="open" class="image-preview">
          <div class="image-preview__image-wrapper">
            <!--            <CSwiper-->
            <!--              v-if="vaultList && vaultList.length"-->
            <!--              :items="vaultList"-->
            <!--              :slider-config="swiperConfig"-->
            <!--              :static-view="false"-->
            <!--              class="image-preview__swiper"-->
            <!--              @click.stop-->
            <!--            >-->
            <!--              <template #slide="{ itemData } = {}">-->
            <!--                <div class="image-preview__field">-->
            <!--                  <div-->
            <!--                    class="image-preview__bg"-->
            <!--                    @click="toggleModal(itemData)"-->
            <!--                  />-->

            <b-img
              v-if="currentSelect.type == 0"
              class="image-preview__image"
              :src="
                currentSelect.type == 0
                  ? currentSelect.url.url
                  : currentSelect.screenshot
              "
            />
            <div class="image-preview__video">
              <MuxVideoPlayer
                v-if="currentSelect.type == 1"
                :video="currentSelect"
                :is-flow-view="false"
              />
            </div>

            <!--                </div>-->
            <!--              </template>-->
            <!--            </CSwiper>-->

            <button
              @click="toggleModalButton"
              class="image-preview__button image-preview__button--close"
            >
              <CIcon name="close" class="image-preview__icon" />
            </button>
          </div>
        </div>
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";

import { mapActions, mapGetters } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";
import CIcon from "@/features/ui/CIcon.vue";
// import CSwiper from "@/features/ui/CSwiper.vue";
import MuxVideoPlayer from "@/features/ui/common/MuxVideoPlayer.vue";

const swiperConfig = {
  slidesPerView: 1,
  spaceBetween: 0,
  slidesOffsetBefore: 0,
  speed: 300,
  navigation: true,
  centeredSlides: true,
};
export default {
  name: "PageVault",
  components: {
    MuxVideoPlayer,
    // CSwiper,
    CIcon,
    PostTile,
    InfinityScroll,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      vault: "media/vault",
    }),
    vaultList() {
      return this.vault?.data || [];
    },
  },
  data() {
    return {
      selectedPost: [],
      isSelectable: false,
      open: false,
      swiperConfig,
      currentSelect: null,
    };
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.$router.replace({ query: null });
  },
  methods: {
    ...mapActions({
      fetchVault: "media/fetchVault",
    }),

    toggleModal(media) {
      console.log("media", media);
      this.open = !this.open;
      this.currentSelect = media;
      // const isImg = media.type == this.imageType;
      // if (isImg) {
      //   this.open = !this.open;
      //   this.activeMedia = media;
      // }
    },

    initState() {
      this.fetchVault().catch(this.checkErrors);
    },

    newMedia() {
      this.$popup.open("AddMediaPopup");
    },

    createQueryConfig(config) {
      return {
        page: this.vault.current_page || 1,
        limit: this.vault.per_page || 30,
        ...config,
      };
    },

    loadMore() {
      const queryConfig = this.createQueryConfig({
        page: this.vault.current_page + 1,
      });
      return this.waitRequest(() => {
        return this.fetchVault(queryConfig).catch(this.checkErrors);
      });
    },
    // onSelect(post) {
    //   this.selectedPost.push(post);
    //   this.$emit('select', post)
    // },
    onUnselect(post) {
      this.selectedPost = this.selectedPost.filter(
        (item) => item.id !== post.id
      );
    },
    toggleModalButton() {
      this.open = !this.open;
      this.currentSelect = null;
    },
  },
};
</script>

<style scoped lang="scss">
.page-vault {
  &__col {
    padding-right: 0;
    padding-left: 0;
  }

  &__list {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.image-preview {
  background-color: rgba($black, 0.5);
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;

  display: block;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    width: 100%;
    height: 90vh;
    //margin-top: 5vh;
  }

  &__swiper {
  }

  &__button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  &__icon {
    width: 25px;
    height: 25px;
    min-height: 25px;
    max-height: 25px;
  }

  &__field {
    position: relative;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
  }

  &__bg {
    color: gold;
    min-height: 100vh;
  }

  &__image {
    align-self: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__video {
    width: 100%;
    margin: auto;
  }
}
</style>
